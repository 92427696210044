import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        iconfont: 'mdi', // default - only for display purposes
    },
    theme: {
        themes: {
            light: {
                primary: '#81C784',
                secondary: '#03A9F4',
                accent: '#FFC107',
                error: '#F44336',
                warning: '#FF9800',
                info: '#2196F3',
                success: '#8BC34A',
                anchor: '#4CAF50',
            },
        },
        dark: false,
    },
    // lang: 'fr',
});

<template>
    <v-app-bar id="app-bar" app clipped-left elevate-on-scroll color="#81C784">
        <v-app-bar-nav-icon
            v-if="isMobile"
            @click.stop="$emit('input', !value)"
        ></v-app-bar-nav-icon>
        <v-spacer v-if="isMobile" />
        <v-toolbar-title>
            <router-link id="logo-router" to="/">
                <picture>
                    <source srcset="/media/noir.webp" type="image/webp" />
                    <img
                        src="/media/noir.png"
                        alt="Logo editions de l'embuscade"
                    />
                </picture>
            </router-link>
        </v-toolbar-title>
        <v-spacer />
        <router-link v-if="!isMobile" exact to="/productions"
            ><b>Nos Productions</b></router-link
        >
        <v-spacer v-if="!isMobile" />
        <router-link v-if="!isMobile" exact to="/books"
            ><b>Nos Livres</b></router-link
        >
        <v-spacer v-if="!isMobile" />
        <router-link v-if="!isMobile" exact to="/about"
            ><b>Qui sommes-nous?</b></router-link
        >
        <v-spacer v-if="!isMobile" />
        <router-link v-if="!isMobile" exact to="/contact"
            ><b>Contact</b></router-link
        >
    </v-app-bar>
</template>
<script>
export default {
    name: 'Appbar',
    props: {
        value: {
            type: Boolean,
        },
    },
    computed: {
        isMobile() {
            return window.innerWidth < 600;
        },
    },
};
</script>

<style lang="scss" scoped>
.v-app-bar {
    z-index: 20 !important;
    a {
        color: black;
        padding: 14px 25px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
    }
}

img {
    width: 8rem;
}
</style>

<style lang="scss">
#logo-router {
    align-content: center;
    display: flex;
}
</style>

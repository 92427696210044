<template>
    <v-app id="app">
        <Navbar v-model="drawer" />
        <v-navigation-drawer v-model="drawer" absolute bottom temporary>
            <v-list nav dense>
                <v-list-item-group
                    v-model="group"
                    active-class="deep-purple--text text--accent-4"
                >
                    <v-list-item>
                        <v-list-item-title
                            ><router-link to="/productions">
                                <b>Nos Productions</b>
                            </router-link>
                        </v-list-item-title>
                    </v-list-item>

                    <v-list-item>
                        <v-list-item-title
                            ><router-link to="/books"
                                ><b>Nos Livres</b>
                            </router-link>
                        </v-list-item-title>
                    </v-list-item>

                    <v-list-item>
                        <v-list-item-title
                            ><router-link to="/about">
                                <b>Qui sommes-nous?</b>
                            </router-link>
                        </v-list-item-title>
                    </v-list-item>

                    <v-list-item>
                        <v-list-item-title
                            ><router-link to="/contact">
                                <b>Contact</b>
                            </router-link>
                        </v-list-item-title>
                    </v-list-item>
                </v-list-item-group>
            </v-list>
        </v-navigation-drawer>
        <v-main>
            <v-container fluid>
                <router-view />
            </v-container>
        </v-main>
    </v-app>
</template>

<script>
import Navbar from '@/components/AppBar';

export default {
    name: 'App',
    components: {
        Navbar,
    },
    data() {
        return {
            drawer: false,
            group: null,
        };
    },
    watch: {
        group() {
            this.drawer = false;
        },
    },
};
</script>

<style lang="scss">
// TODO: move this into a more general css setup file
header {
    z-index: 10;
}

#app {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    display: flex;
    background-color: $background-color;
}

#nav {
    padding: 30px;

    a {
        font-weight: bold;
        color: #2c3e50;

        &.router-link-exact-active {
            color: #42b983;
        }
    }
}
</style>

import { getOpusesId, getBlogContentsId } from '../utils/api';

export const routes = [
    {
        path: '/',
        name: 'Home',
        component: () =>
            import(/* webpackChunkName: "home" */ '../views/Home.vue'),
        meta: {
            sitemap: {
                changefreq: 'monthly',
                priority: 1.0,
            },
        },
    },
    {
        path: '/about',
        name: 'About',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "about" */ '../views/About.vue'),
    },
    {
        path: '/productions',
        name: 'Productions',
        component: () =>
            import(
                /* webpackChunkName: "productions" */ '../views/Productions.vue'
            ),
        meta: {
            sitemap: {
                changefreq: 'weekly',
                priority: 1.0,
            },
        },
    },
    {
        path: '/blogcontent/:id',
        name: 'BlogContent',
        component: () =>
            import(
                /* webpackChunkName: "blogcontent" */ '../views/BlogContent.vue'
            ),
        meta: {
            sitemap: {
                slugs: async () => await getBlogContentsId(),
            },
        },
    },
    {
        path: '/opus/:id',
        name: 'Opus',
        meta: {
            sitemap: {
                slugs: async () => await getOpusesId(),
            },
        },
        component: () =>
            import(/* webpackChunkName: "opus" */ '../views/Opus.vue'),
    },
    {
        path: '/contact',
        name: 'Contact',
        component: () =>
            import(/* webpackChunkName: "contact" */ '../views/Contact.vue'),
        children: [
            {
                path: '',
                component: () =>
                    import(
                        /* webpackChunkName: "contact" */ '../components/contact/Form.vue'
                    ),
                meta: {
                    sitemap: {
                        changefreq: 'yearly',
                        priority: 0.9,
                    },
                },
            },
            {
                path: 'submission',
                component: () =>
                    import(
                        /* webpackChunkName: "contact" */ '../components/contact/Submission.vue'
                    ),
                meta: {
                    sitemap: {
                        changefreq: 'yearly',
                        priority: 0.9,
                    },
                },
            },
            {
                path: 'professional',
                component: () =>
                    import(
                        /* webpackChunkName: "contact" */ '../components/contact/Professional.vue'
                    ),
                meta: {
                    sitemap: {
                        changefreq: 'yearly',
                        priority: 0.9,
                    },
                },
            },
        ],
    },
    {
        path: '/books',
        name: 'Books',
        component: () =>
            import(/* webpackChunkName: "about" */ '../views/Books.vue'),
        meta: {
            sitemap: {
                changefreq: 'weekly',
                priority: 1.0,
            },
        },
    },
    {
        path: '/legal/:section?',
        name: 'Legal',
        component: () =>
            import(/* webpackChunkName: "about" */ '../views/Legal.vue'),
        meta: {
            sitemap: {
                changefreq: 'weekly',
                priority: 0.4,
                slugs: ['cgv', 'rgpd', 'legal-mentions'],
            },
        },
    },
    {
        path: '/buy',
        name: 'Buy',
        component: () =>
            import(/* webpackChunkName: "buy" */ '../views/PaymentProcess.vue'),
        meta: {
            sitemap: {
                changefreq: 'monthly',
                priority: 0.2,
            },
        },
    },
];

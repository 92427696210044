import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import router from './router';
import Vue2Filters from 'vue2-filters';
import VueProgressiveImage from 'vue-progressive-image';
import Toasted from 'vue-toasted';

Vue.use(Vue2Filters);
Vue.use(VueProgressiveImage);
Vue.use(Toasted, {
    position: 'bottom-center',
    fullWidth: true,
    duration: 2000,
});

Vue.config.productionTip = false;

new Vue({
    vuetify,
    router,
    render: (h) => h(App),
}).$mount('#app');

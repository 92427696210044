import axios from 'axios';

const apiUrl = process.env.VUE_APP_API_URL;
export async function getOpusesId() {
    const res = await axios.get(apiUrl + '/opuses/');
    const { data } = res;
    return data.map((value) => value.id);
}

export async function getBlogContentsId() {
    const res = await axios.get(apiUrl + '/episodes');
    const { data } = res;
    return data.map((value) => value.id);
}
